@import '../colors.scss';

.project-page {
  color: white;

  >div:nth-child(odd) {
    background-color: black;
  }

  >div:nth-child(even) {
    background-color: $dark-grey;
  }
}