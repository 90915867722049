@import '../../colors.scss';

#LandingPage {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  font-size: 1.2rem;
  letter-spacing: 2px;

  b {
    color: orange;
    font-weight: bolder;
  }

  .landing-body {
    text-align: center;
    width: 600px;
    padding: 10px;

    .typewriter .typewriter-container h1 {
      font-size: 2.6rem;
    }

    .landing-body-contents {
      font-family: "Karla", sans-serif;
    }
  }

  .tag-line {
    color: grey;
  }

  @media screen and (max-width: 767px) {
    .landing-body {
      width: 300px;
    }
  }
}

.down-arrow {
  position: absolute;
  bottom: 0px;
  padding-bottom: 20px;
  color: grey;
  font-size: 0.8rem;
  font-family: 'Menlo', serif;
  letter-spacing: 2px;
}

.typewriter-container {
  display:inline-block;
}

.typewriter h1 {
  text-align: center;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .10em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .05em; /* Adjust as needed */
  animation: 
    typing 1.2s steps(40, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}