@import '../../../colors.scss';

$block-height: 450px;
$inter-margin: 1vh;
$block-width: 25vw;

.project-mini-block {
  width: $block-width;
  height: $block-height;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  position: relative;
  top: 0;
  transition: all ease 0.5s;
  transition-property: top, border-left;
  border-left: 0px solid $orange;

  &:hover {
    top: -10px;
    border-left: 2px solid $orange;
  }

  img {
    width: $block-width;
    height: $block-height;
    object-fit: cover;
    border-radius: 10px;
    z-index: 0;
    filter: grayscale(50%) opacity(0.8);
  }

  .project-mini-content {
    position:absolute;
    width: $block-width;
    height: $block-height;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    display: flex;
    flex-direction: column;
    border-radius: 10px;

    .project-mini-title {
      font-family: 'Lato', sans-serif;
      font-size: 1.7rem;
      padding: $inter-margin 30px $inter-margin 30px;
      margin-top: 5vh;
    }

    .project-mini-taglines {
      display: flex;
      padding: 0 $inter-margin $inter-margin 30px;
      letter-spacing: 2px;
      color: $orange;

      .project-mini-subtitle {
        flex: 1;
      }
    }

    .project-mini-divider {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      margin: $inter-margin 30px 0 30px;
    }

    .project-mini-text {
      padding: $inter-margin 30px $inter-margin 30px;
      line-height: 1.5rem;
      flex: 1;
      font-family: "Karla", sans-serif;
    }

    .project-mini-stack {
      padding: 0 30px $inter-margin 30px;
      // font-family: 'Menlo', serif;
      font-size: 0.9rem;
      letter-spacing: 2px;
      color: $grey;
    }

    .project-mini-icons {
      padding: 0 30px 30px 30px;
      display: flex;
      color: $orange;
      flex-direction: row;

      > div {
        align-self: center;
      }

      .project-mini-date {
        font-size: 0.8rem;
        flex: 1;
        font-family: 'Menlo', serif;

        .project-button {
          width: 10vw;
          font-family: 'Menlo', serif;
          letter-spacing: 2px;
          color: $orange;
          background-color: Transparent;
          border: 1px solid $orange;
          border-radius: 5px;
          padding: 5px 0 5px 0;
          transition: 0.5s;
          display: flex;
          justify-content: center;
    
          &:hover {
            background-color: $orange;
            border: 1px solid $orange;
            color: white;
          }
        }
      }

      a {
        font-size: 2rem;
        color: $orange;
        transition: 0.2s;
        padding: 0px 5px 0px 5px;

        &:hover {
          color: white;
        }
      }
    }
  }
}
