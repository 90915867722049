@import '../colors.scss';

#AboutPage {
    background-color: black;
    display: flex;
    justify-content: center;
    // align-items: center;
    padding: 25vh 0 20vh 0;

    .about-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        img {
            height: 60vh;
            border-radius: 10px;
        }

        .about-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 40px;
            
            @media screen and (max-width: 767px) {
                padding-left: 0;
            }

            .about-header {
                font-size: 2.5rem;
                text-align: left;
                @media screen and (max-width: 767px) {
                    text-align: center;
                }
                  
            }
    
            .about-text {
                font-family: 'Karla', sans-serif;
                width: 30vw;
                padding-top: 1.5rem;
                color: $light-grey;
                font-size: 1.1rem;

                b{
                    color: white;
                }

                @media screen and (max-width: 767px) {
                    width: 70vw;
                }
            }

            @media screen and (max-width: 767px) {
                padding-top: 40px;
                padding-left: 0px;
            }
        }
    }

}