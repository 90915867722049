@import '../../../colors.scss';

.project-block {
  // margin-left: 10vw;
  margin-top: 10vh;
  margin-bottom: 10vh;
  // width: 100vw;
  // border: 1px solid red;
  display: flex;
  flex-direction: row;
  // transition: transform .4s;

  &:hover {
    // transform: scale(1.08);
  }

  .project-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30vw;

    .project-title {
      font-family: 'Lato', serif;
      font-size: 2.5rem;
      padding-left: 20px;
      padding-bottom: 10px;
    }

    .project-subtitle {
      letter-spacing: 2px;
      padding-left: 20px;
      padding-bottom: 20px;
      color: $orange;
    }

    .project-text {
      z-index: 1;
      position: relative;
      width: 33vw;
      height: 15vh;
      padding: 20px;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      font-family: "Karla", sans-serif;
    }

    .project-text-bg {
      z-index: 1;
      position: relative;
      width: 33vw;
      height: 15vh;
      border-radius: 5px;
      background-color: black;
      opacity: 0.9;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    }

    .project-stack {
      padding-top: 20px;
      padding-left: 20px;
      font-family: 'Menlo', serif;
      color: $grey;
      font-size: 0.8rem;
    }

    .project-button {
      width: 150px;
      font-family: 'Menlo', serif;
      letter-spacing: 2px;
      color: $orange;
      background-color: Transparent;
      border: 1px solid $orange;
      border-radius: 5px;
      padding: 5px 0 5px 0;
      margin-top: 20px;
      margin-left: 20px;
      transition: 0.5s;

      &:hover {
        background-color: $orange;
        border: 1px solid $orange;
        color: white;
      }
    }
  }

  .img-container {
    display: flex; 
    justify-content: flex-end;

    img {
      object-fit: cover;
      width: 50vw;
      height: 45vh;
      border-radius: 10px;
      z-index: 0;
      transition: filter ease 0.5s;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
      filter: grayscale(60%) opacity(0.8);

      &:hover {
        filter: none;
      }
    }
  }
  
}