@import '../../../colors.scss';

$navHeight: 70px;
$margins: 10vw;
$bodyPadding: 70px;

.heading-block {
  display: flex; 
  flex-direction: column;

  b {
    color: white;
  }
  
  img {
    width: 100vw;
    height: 45vh;
    object-fit: cover;
    z-index: 0;
    filter: grayscale(50%) opacity(0.8);
  }

  .heading-content {
    padding: $bodyPadding $margins $bodyPadding $margins;

    .heading-title {
      font-size: 3rem;
      font-weight: bolder;
    }

    .heading-block-divider {
      border-bottom: 1px solid $grey;
      margin: 30px 0 30px 0;
    }

    .heading-columns {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .column-section {
        width: 25%;
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 767px) {
          display: block;
        }
        
        > div:nth-of-type(1) {
          font-family: "menlo", serif;
          color: $orange;
          font-weight: bold;
          padding-right: 20px;
          padding-top: 0.6rem;
          font-size: 0.8rem;
          @media screen and (max-width: 767px) {
            padding-bottom: 1rem;
          }
        }

        > div:nth-of-type(2) {
          font-family: "Karla", sans-serif;
          font-size: 1rem;
          padding-right: 5px;
          line-height: 2rem;
        }
      }
    }

    .heading-body {
      color: $light-grey;
      margin-top: 30px;
      font-size: 1.3rem;
      line-height: 2.5rem;
      font-family: "Karla", sans-serif;
    }

    .heading-buttons {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      a {
        font-size: 1.8rem;
        color: $orange;
        padding: 0 10px 5px 10px;

        &:hover {
          color: white;
          cursor: pointer;
        }
      }
    
    }
  }

}