@import url('https://fonts.googleapis.com/css?family=Lato:400,700i&display=swap');

body, html {
  overflow-x: hidden;

  button {
    outline: none;
  }
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

@media screen and (max-width: 767px) {
  html {
    /* Reducing base font size will reduce all rem sizes */
    font-size: 13px;
  }
}

@media screen and (max-width: 1000px) {
  html {
    /* Reducing base font size will reduce all rem sizes */
    font-size: 14px;
  }
}


// @media screen and (min-width: 1200px) {
//   html {
//     /* Reducing base font size will reduce all rem sizes */
//     font-size: 17px;
//   }
// }

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 1.8rem;
  letter-spacing: 2px;
}

body {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  color: white;

  a:hover, button:hover {
    cursor: pointer;
  }

  // margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
