@import '../../../colors.scss';

$navHeight: 70px;
$margins: 10vw;
$bodyPadding: 70px;

.section {
  padding: $bodyPadding $margins $bodyPadding $margins;

  .columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .left-column {
      display: flex;
      flex-direction: column;
      width: 35%;
      max-height: 100%;
      padding-right: 30px;

      @media screen and (max-width: 767px) {
       display: flex;
       flex-direction: column;
       width: 100%;
      }
      
      .section-timeline {
        font-family: 'Menlo', sans-serif;
        color: $orange;
        letter-spacing: 2px;
        margin-bottom: 1rem;
      }

      .section-title {
        font-size: 2.5rem;
        margin-bottom: 2.5rem;
      }

      .section-tagline {
        font-size: 1.5rem;
        line-height: 2.5rem;
        font-family: "Karla", sans-serif;
      }
    }

    .right-column {
      padding-top: 2.5rem;
      padding-left: 30px;
      width: 55%;
      font-size: 1.3rem;
      line-height: 2.5rem;
      color: $light-grey;
      font-family: "Karla", sans-serif;

      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 0;
       }

      > div {
        padding-bottom: 2rem;
      }

      b {
        color: white;
      }
    }
  }

  .section-images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    
    img {
      width: 35vw;
      height: auto;
      margin: 30px 5px;
      border-radius: 10px;
      transition: all ease .4s;
      transition-property: transform, filter;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
      filter: grayscale(60%) opacity(0.8);

      &:hover {
        cursor: pointer;
        transform: scale(1.04);
        filter: none;
      }
    }

    .img-sm {
      width: 50vw;
      height: auto;

      @media screen and (max-width: 767px) {
        width: 80vw;
      }
    }
  }
}