@import '../colors.scss';

#Navigation {
  z-index: 5;
  width: 100vw;
  // background-color: rgba(242, 169, 60, 0.8);
  // background-color: rgba(0, 0, 0, 0.8);
  background-color: $dark-grey;
  border-bottom: 1px solid black;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  height: 70px;
  display: flex;
  justify-content: center;
  flex-direction: row;

  #links {
    padding-right: 5vw;
    width: 50vw;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 767px) {
      padding-right: 0;
      justify-content: center;
    }
    
    a {
      align-self: center;
      margin-left: 20px;
      margin-right: 20px;
      color: grey;
    }

    a:hover {
      color: white;
    }
    
  }

  #name-link {
    padding-left: 5vw;
    width: 50vw;
    // border: 1px solid red;
    align-self: center;
    display:flex;
    justify-content: flex-start;

    a {
      align-self: center;
    }
  }

  a {
    position: relative;
    background:
       linear-gradient(
         to bottom, orange 0%,
         orange 100%
       );
      background-position: 0 100%;
      background-repeat: repeat-x;
      background-size: 0px 0px;
    color: white;
    text-decoration: none;
    transition: background-size .2s;
    letter-spacing: 2px;
    font-size: 0.8rem;
    font-family: 'Menlo', serif;
  }
  
  a:hover {
    background-size: 1rem 0.5rem;
  }
}

// @media screen and (max-width: 767px) {
//   #Navigation {
//     display: none;
//   }
// }